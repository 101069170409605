<template>
  <div class="">
    <b-navbar class=" f-16 f-w-600 px-md-5 d-none d-md-flex" toggleable="sm" type="dark" variant="primary" sticky>

      <b-navbar-brand to="/"><img src="@/assets/images/ecart-logo.png" alt="" class=" img-fluid  img-100">
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class=" ml-auto align-items-center ">
          <b-nav-item @click=" fnToDashBoard() " v-if="  this.$store.state.session.user.id != null ">
            <i class="fa fa-tachometer-alt mr-1"></i>
            <span v-text=" $t('general.dashboard')"></span>
          </b-nav-item>

          <b-nav-item @click="signIn()" v-if=" this.$store.state.session.user.id ==  null ">
            <b-button variant="primary">
              <i class="fa fa-user-circle mr-1"></i>
              <span v-text=" $t('general.login')"></span>
            </b-button>
          </b-nav-item>

          <b-nav-item @click="signUp()" v-if=" this.$store.state.session.user.id ==  null ">
            <b-button variant="success" class="px-4" pill>
              <span v-text=" $t('general.signup')"></span>
            </b-button>
          </b-nav-item>

          <b-nav-item>
            <Languages variant="primary" />
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class=" ">
      <transition name="fadeIn" enter-active-class="animated fadeIn ">
        <router-view class="view "></router-view>
      </transition>
      <Footer />
    </div>
  </div>
</template>

<script>
  import Languages from "@/components/Languages"
  import Footer from './components/footer'
  export default {
    name: 'landingPage',
    data() {
      return {}
    },
    components: {
      Footer,
      Languages,
    },
    computed: {},
    created() {},
    watch: {},
    methods: {
      signIn() {
        window.location.href =`${process.env.VUE_APP_ECART_API}signin`;
      },
      signUp() {
        window.location.href =`${process.env.VUE_APP_ECART_API}signup`;
      },
      fnToDashBoard() {
        if (this.$store.state.session.user.complete) {
          this.$router.push('/dashboard/dashboard');
        } else {
          this.$store.commit('menu/setStepNumber', 1);
          setTimeout(() => {
            this.$router.push('/dashboard/account?integration=true');
          }, 300);
        }
      },
    }
  }
</script>
<style lang="scss">

</style>